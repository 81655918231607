import React from "react";
import {
  NotificationPreference,
  NotificationSourcePreference,
  useUpdateUserNotificationPreferenceMutation,
} from "services/graphql";
import Flex from "app/components/Flex";
import { Title, OptionsDiv, OptionsWrapper } from "../styles";
import { NotificationToggle } from "../NotificationToggle";

const EMAIL_NOTIFICATIONS = [
  // {
  //   label: "Reminders",
  //   sourcePreference: NotificationSourcePreference.EmailReminder,
  // },
  {
    label: "Newsletter",
    sourcePreference: NotificationSourcePreference.EmailNews,
  },
  // {
  //   label: "Feedback",
  //   sourcePreference: NotificationSourcePreference.EmailFeedback,
  // },
  // {
  //   label: "Support",
  //   sourcePreference: NotificationSourcePreference.EmailSupport,
  // },
];

interface Props {
  notificationPreferences: NotificationPreference[];
}

export function EmailNotificationSettings({ notificationPreferences }: Props) {
  const [
    updateUserNotificationPreference,
  ] = useUpdateUserNotificationPreferenceMutation();

  return (
    <Flex flexDirection="column">
      <Title>Email Notifications</Title>
      <Flex flexDirection="column">
        <OptionsDiv>
          <OptionsWrapper>
            {EMAIL_NOTIFICATIONS.map(option => {
              const sourcePreference = notificationPreferences?.find(
                preference => preference.source === option.sourcePreference
              );
              const isEnabled = sourcePreference?.enabled;
              return (
                <NotificationToggle
                  key={option.sourcePreference}
                  onClick={() => {
                    updateUserNotificationPreference({
                      variables: {
                        input: {
                          id: option.sourcePreference,
                          source: option.sourcePreference,
                          enabled: !isEnabled,
                        },
                      },
                    });
                  }}
                  isSelected={isEnabled}
                  name={option.label}
                />
              );
            })}
          </OptionsWrapper>
        </OptionsDiv>
      </Flex>
    </Flex>
  );
}
